<script>
import { OptionsComponent } from '@drapejs/core';

/* eslint-disable no-param-reassign */
import { computed } from 'vue';
import { setProductMeta } from '@/composables/useMeta';
import DisplayPrice from '@/components/DisplayPrice.vue';
import ProductOverview from '@/components/ProductOverview/ProductOverview.vue';
import ProductDetails from '@/components/ProductDetails';
import ProductDetailTabs from '@/components/ProductDetailTabs';
import ModalContainer from '@/components/ModalContainer.vue';
import AddToCart from '@/components/AddToCart.vue';
import BackInStockSignup from '@/components/BackInStock/BackInStockSignup.vue';
// import PostAddToCart from "@/components/PostAddToCart.vue";

export default {
  extends: OptionsComponent,
  inject: ['registerModal', 'unregisterModal'],
  components: {
    DisplayPrice,
    ProductOverview,
    ProductDetails,
    ProductDetailTabs,
    AddToCart,
    ModalContainer,
    BackInStockSignup
    // PostAddToCart,
  },
  data() {
    return {
      isAddToCartOpen: false,
      isBackInStockOpen: false,
      postAddToCartRowId: null,
      selectedQuantity: 1,
      selectedPrintData: null,
      addToCartTitle: ''
    };
  },
  provide() {
    return {
      $addToCartContextReactive: computed(() => ({
        selectedVariant: this.selectedVariant,
        product: this.product,
        productColors: this.productColors,
        colorSizes: this.colorSizes,
        openAddToCartModal: this.openAddToCartModal,
        openBackInStockModal: this.openBackInStockModal,
        setPostAddToCartRowId(rowId) {
          this.postAddToCartRowId = rowId;
        }
      }))
    };
  },
  computed: {
    isAuthenticated() {
      return this.$user?.isAuthenticated || false;
    },
    sizeOrder() {
      return (
        this.$channel?.sizes?.reduce((p, c, i) => {
          p[c.value] = i;
          return p;
        }, {}) || {}
      );
    },
    product() {
      return this.$page.dataJson?.dataJson?.product;
    },
    productId() {
      return this.product?.id;
    },
    blocks() {
      return this.$page.blocks;
    },
    selectedVariant() {
      const { product } = this;
      if (!product) return null;
      const { size, color, giftcardType } = this.$route.query;
      const flexibleGiftcardVariant = this.flexibleGiftcardVariant;
      for (const variant of product.otherVariants) {
        if (color && !this.$isColorVariantMatch(color, variant)) continue;
        if (size && variant.fields.Size?.value !== size) continue;
        if (giftcardType && variant.fields.GiftcardType !== giftcardType)
          continue;
        if (flexibleGiftcardVariant !== null) return flexibleGiftcardVariant;
        return variant;
      }
      return null;
    },
    flexibleGiftcardVariant() {
      const { product } = this;
      if (!product) return null;
      if (!product.isGiftcard) return null;
      const flexibleVariants = product.otherVariants.filter(
        (v) => v.fields.IsFlexibleType
      );
      if (flexibleVariants.length > 0) {
        return flexibleVariants[0];
      }
      return null;
    },
    articleNumber() {
      return this.selectedVariant?.articleNumber;
    },
    productColors() {
      if (!this.product) return [];

      return this.product.otherVariants.reduce(
        (p, c) => {
          const colorString = this.$variantToColorString(c);
          if (!p.index[colorString]) {
            p.index[colorString] = true;
            p.result.push(c);
          }
          return p;
        },
        { result: [], index: {} }
      ).result;
    },
    colorSizes() {
      if (!this.selectedVariant) return [];
      const color = this.$variantToColorString(this.selectedVariant);
      let sizes = [];

      if (!color) {
        sizes = this.product.otherVariants.filter((v) => !v.fields.Color);
      } else {
        sizes = this.product.otherVariants.filter((v) =>
          this.$isColorVariantMatch(color, v)
        );
      }

      sizes.sort(
        (a, b) => this.getSizeOrderIndex(a) - this.getSizeOrderIndex(b)
      );
      return sizes;
    }
  },
  methods: {
    getSizeOrderIndex(sizeVariant) {
      const size = sizeVariant.fields.Size?.value;
      if (!size) return 0;
      return this.sizeOrder[size] || 0;
    },
    closeAddToCartModal(rowId) {
      // TODO: Enable the post add to cart component later on when it will make more sense
      // this.postAddToCartRowId = rowId;
      this.isAddToCartOpen = false;

      this.openQuickCart();
    },
    openAddToCartModal({
      quantity,
      printData,
      title,
      isShowStockRequest
    } = {}) {
      if (isShowStockRequest || this.isAuthenticated) {
        this.selectedQuantity = quantity || 1;
        this.selectedPrintData = printData || null;
        this.addToCartTitle = title || this.$globalTexts.global__add_to_cart;
        this.isAddToCartOpen = true;
      } else if (!this.$route?.query?.action) {
        const search = this.$route.query || {};
        const query = {
          ...search,
          action: 'add'
        };

        this.$navigateToLoginPage(query);
      }
    },
    openBackInStockModal() {
      this.isBackInStockOpen = true;
    },
    openQuickCart() {
      this.$mitt.emit('openQuickCart');
      this.postAddToCartRowId = null;
    },
    pushViewItemEvent() {
      if (typeof dataLayer === 'undefined' || !this.articleNumber) {
        return;
      }

      const itemName =
        this.product?.googleAnalyticsName || this.product?.fields?._name;

      const discount = this.selectedVariant.campaignPrice
        ? this.selectedVariant.price - this.selectedVariant.campaignPrice
        : 0;

      const price = this.selectedVariant.campaignPrice
        ? this.selectedVariant.campaignPrice
        : this.selectedVariant.price;

      const categoryPath = this.product.googleAnalyticsCategoryPath || [];

      const item = {
        item_id: this.selectedVariant.articleNumber,
        item_name: itemName,
        discount,
        price
      };

      for (let i = 0; i < Math.min(5, categoryPath.length); i++) {
        if (i == 0) {
          item.item_category = categoryPath[i];
        } else {
          item[`item_category${i + 1}`] = categoryPath[i];
        }
      }

      dataLayer.push({
        event: 'view_item',
        currency: this.$cart?.currency?.id,
        value: price,
        items: [item]
      });
    }
  },
  watch: {
    isAddToCartOpen(isOpen) {
      if (isOpen) {
        this.registerModal('Product:AddToCart');
      } else {
        this.unregisterModal('Product:AddToCart');
      }
    },
    postAddToCartRowId(rowId) {
      if (rowId) {
        this.registerModal('Product:PostAddToCart');
      } else {
        this.unregisterModal('Product:PostAddToCart');
      }
    },
    productId: {
      immediate: true,
      handler() {
        this.pushViewItemEvent();
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem('add-to-cart')) {
      sessionStorage.removeItem('add-to-cart');
      this.openAddToCartModal();
    }
    setProductMeta(this.selectedVariant, this.product);
  },
  beforeUnmount() {
    this.unregisterModal('Product:AddToCart');
    this.unregisterModal('Product:PostAddToCart');
  },
};
</script>

<template>
  <div>
    <product-overview />
    <product-details />
    <product-detail-tabs />
    <blocks-repeater v-if="blocks" :blocks="blocks.Main" />

    <teleport to="body">
      <modal-container
        v-if="isAddToCartOpen"
        @close-modal="isAddToCartOpen = false"
        position="center"
        :show-exit-button="true"
        :show-back-button="false"
        :show-left-exit-button="false"
        :max-width="320"
      >
        <add-to-cart
          :title="addToCartTitle"
          :selected-quantity="selectedQuantity"
          :selected-print-data="selectedPrintData"
          @added-to-cart="closeAddToCartModal"
        />
      </modal-container>
      <modal-container
        v-if="postAddToCartRowId"
        @close-modal="postAddToCartRowId = null"
        position="center"
        :show-exit-button="true"
        :show-back-button="false"
        :show-left-exit-button="false"
        :max-width="320"
      >
        <!-- <post-add-to-cart :row-id="postAddToCartRowId" @open-cart="openCart" /> -->
      </modal-container>
      <modal-container
        class="product_back-in-stock-modal"
        v-if="isBackInStockOpen"
        @close-modal="isBackInStockOpen = false"
        position="center"
        :show-exit-button="true"
        :show-back-button="false"
        :show-left-exit-button="false"
      >
        <back-in-stock-signup :article-number="articleNumber" />
      </modal-container>
    </teleport>
  </div>
</template>

<style>
.product_back-in-stock-modal .modal-container {
  max-width: 460px;
}
</style>
